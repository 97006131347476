import { createSlice } from '@reduxjs/toolkit'

interface toasterProps {
  id?: number,
  type?: string,
  title?: string,
  message?: any,
  closeButton?: boolean
}

interface initialStateInt {
  toaster: toasterProps[]
}

// initial state
const initialState: initialStateInt = {
  toaster: []
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    toasterAlert: (state, { payload }) => {
      const newPayload = Object.assign({}, payload);
      newPayload.id = Math.floor(Math.random() * 100);
      state.toaster = [...state.toaster, newPayload]
    },
    toasterRemove: (state, { payload }) => {
      state.toaster.splice(state.toaster.findIndex((arrow: any) => arrow.id === payload), 1);
    }
  }
})

export const { toasterAlert, toasterRemove } = toasterSlice.actions

export default toasterSlice.reducer
