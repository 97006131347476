export const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderStyle: 'hidden',
    color: state.isFocused ? '#5c6873' : '',
    outline: state.isFocused ? '0' : '',
    borderColor: state.isFocused ? '#8ad4ee' : '',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(50, 31, 219, 0.25)' : ''
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 3 }),
  menu: (provided: any) => ({ ...provided, zIndex: 3 })
};

export const physicianSelectOptions = [
  { value: 'None', label: 'None' },
  { value: 'Physician 1', label: 'Physician 1' }
]

export const preventEnterSubmit = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const rawOptionsList = [
  { id: 1, name: 'Oxygen  + 33%', value: 'oxygen' },
  { id: 2, name: 'Diastolic  - 33%', value: 'diastolic' },
  { id: 3, name: 'Systolic  + 23%', value: 'systolic' },
  { id: 4, name: 'Pulse', value: 'pulse' },
  { id: 5, name: 'Temperature', value: 'temperature' },
  { id: 6, name: 'Weight', value: 'weight' },
  { id: 7, name: 'Fasting Blood Glucose', value: 'preprandial' },
  { id: 8, name: 'Non-Fasting Blood Glucose', value: 'postprandial' },
  { id: 9, name: 'INR', value: 'inr' }
];

export const TrimOptionsList = () => {
  const optionsArray = [];
  for (let i = 0; i <= 45; i++) {
    optionsArray.push(i);
    i = i + 4;
  }
  return optionsArray;
};
export const medicalTypesArray = ['None', 'MA', 'NP', 'PA', 'MD'];

export const PERMISSIONS = {
  TASKMODULE: 'taskmodule',
  TASKVIEW: 'taskview',
  TASKEDIT: 'taskedit',
  INVENTORYMODULE: 'inventorymodule',
  INVENTORYVIEW: 'inventoryview',
  INVENTORYEDIT: 'inventoryedit',
  DASHBOARDMODULE: 'dashboardmodule',
  SETTINGMODULE: 'settingmodule',
  USERVIEW: 'userview',
  USEREDIT: 'useredit',
  PROVIDERVIEW: 'providerview',
  PROVIDEREDIT: 'provideredit',
  ORGANIZATIONVIEW: 'organizationview',
  ORGANIZATIONEDIT: 'organizationedit',
  REPORTMODULE: 'reportmodule',
  CHATMODULE: 'chatmodule',
  PATIENTOPERATIONMODULE: 'patientoperationmodule',
  PATIENTHEALTHMODULE: 'patienthealthmodule',
  PATIENTINDIVIDUALMODULE: 'patientindividualmodule',
  PATIENTALLEDIT: 'patientalledit',
  PATIENTBASICVIEW: 'patientbasicview',
  PATIENTNOTEVIEW: 'patientnoteview',
  PATIENTREADINGVIEW: 'patientreadingview',
  PATIENTPERIODVIEW: 'patientperiodview',
  PATIENTEVENTVIEW: 'patienteventview',
  PATIENTMANUALVIEW: 'patientmanualview',
  PATIENTSCHEDULEVIEW: 'patientscheduleview',
  PATIENTFAXVIEW: 'patientfaxview',
  PATIENTSURVEYVIEW: 'patientsurveyview',
  PATIENTREFERRALVIEW: 'patientreferralview',
  PATIENTORGANIZATIONVIEW: 'patientorganizationview',
  LOCATIONMODULE: 'locationmodule',
  PATIENTGRIDDEFAULT: 'patientgriddefault',
  PATIENTGRIDPROVIDER: 'patientgridprovider',
  PATIENTUSERVIEW: 'patientuserview'
}
