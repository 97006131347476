import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosPost } from '../utils/axios'
import apiRouter from '../utils/apiRouter'

interface initialStateInt {
  authToken: string;
  isLogin: boolean;
  isLoading: boolean;
  loggedInUser:any;
  timezoneArr: any[];
  showSidebar: boolean;

}

// initial state
const initialState: initialStateInt = {
  authToken: '',
  isLogin: false,
  isLoading: true,
  loggedInUser: {},
  timezoneArr: [],
  showSidebar: true

};

// export const userLogin = createAsyncThunk(
//   'login/loginUser',
//   async (credentials: any, thunkAPI: any) => {
//     const response = await axiosPost(apiRouter.LOGIN, credentials)
//    if (response.data.ChallengeName !== undefined && response.data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
//      return response.data
//         } else if (response.data.AuthenticationResult !== undefined && response.data.AuthenticationResult.AccessToken !== undefined) {
//           localStorage.setItem('RPMAuthToken', response.data.AuthenticationResult.AccessToken);
//         }
//         return response.data
//       }
// );

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthToken: (state, { payload }) => {
      state.authToken = payload
    },
    setIsLogin: (state, { payload }) => {
      state.isLogin = payload
    },
    setLoginUser: (state, { payload }) => {
      state.loggedInUser = payload
    }
  }
  // extraReducers: (builder) => {
  //  builder.addCase(userLogin.pending, (state) => {
  //     state.authToken = ''
  //   })
  //   builder.addCase(userLogin.fulfilled, (state, { payload }) => {
  //     state.authToken = payload
  //     state.isLogin = true
  //   })
  //   builder.addCase(userLogin.rejected, (state) => {
  //     state.authToken = ''
  //   })
  // }
})

// export const allUsers = (state) =>
//   state.users

export const { setAuthToken, setIsLogin, setLoginUser } = loginSlice.actions

export default loginSlice.reducer
