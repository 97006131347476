import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { axiosGet, axiosPost } from '../utils/axios'
// import apiRouter from '../utils/apiRouter'

interface initialStateInt {
  patientChannelList: [],
  unreadMessageList: [],
  pubnubChannelList: [],
  unreadMessageCount: number,
  pubnubToken: string,
  sidebarUsers: []
}

// initial state
const initialState: initialStateInt = {
  patientChannelList: [],
  unreadMessageList: [],
  pubnubChannelList: [],
  unreadMessageCount: 0,
  pubnubToken: '',
  sidebarUsers: []
};

export const pubnubSlice = createSlice({
  name: 'pubnubRedux',
  initialState,
  reducers: {
    setPatientChannelList: (state, { payload }) => {
      state.patientChannelList = payload
    },
    setUnreadMessageList: (state, { payload }) => {
      state.unreadMessageList = payload
    },
    setPubnubChannelList: (state, { payload }) => {
      state.pubnubChannelList = payload
    },
    setUnreadMessageCount: (state, { payload }) => {
      state.unreadMessageCount = payload
    },
    setPubnubToken: (state, { payload }) => {
      state.pubnubToken = payload
    },
    setSidebarUsers: (state, { payload }) => {
      state.sidebarUsers = payload
    }
  }
})

// export const allUsers = (state) =>

export const { setPatientChannelList, setUnreadMessageList, setPubnubChannelList, setUnreadMessageCount, setPubnubToken, setSidebarUsers } = pubnubSlice.actions

export default pubnubSlice.reducer
