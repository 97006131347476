import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import { icons } from './assets/icons';
import reportWebVitals from './reportWebVitals';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Provider } from 'react-redux';
import { store } from './store';
import axios from 'axios';
import Alert from './reusable/Alert';

Chart.register = annotationPlugin;
React.icons = icons;

// console.log('store', store.getState())
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store} >
      <App/>
      <Alert />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// axios.interceptors.request.use(function (config: any) {
//   config.headers = { ...config.headers, 'Content-Type': 'application/json' }

//   if (localStorage.getItem('RPMAuthToken')) {
//     config.headers = { ...config.headers, Authorization: localStorage.getItem('RPMAuthToken') }
//   }
//   return config;
// }, function () {
// })

// axios.interceptors.response.use(
//   response => {
//     console.log('yes', response)
//     return response.status === 200 ? response.data : response
//   },
//   error => {
//     if (error.response && error.response.status && error.response.status === 401) {
//       localStorage.removeItem('RPMAuthToken');
//       window.location.reload();
//     }
//     // let errorMsg = error;
//     // if (error.response && error.response.data) {
//     //   if (error.response.data.hint) {
//     //     errorMsg = error.response.data.hint + '<br/>';
//     //   }
//     //   if (error.response.data.error) {
//     //     errorMsg = errorMsg ? errorMsg + error.response.data.error : error.response.data.error;
//     //   }
//     // }

//     // GlobalService.showErrorMsg(errorMsg);
//     return Promise.reject(error);
//   }
// )

reportWebVitals();
