import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';
import { patientSVG } from './dashboard/patient';
import { clockSVG } from './dashboard/clock';
import { userSVG } from './dashboard/user';
import { taskSVG } from './dashboard/task';
import { pulseSVG } from './dashboard/pulse';

// import {
//   cibSkype,
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   cibStackoverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibStripe,
//   cibPaypal,
//   cibGooglePay,
//   cibCcAmex,
//   cifUs,
//   cifBr,
//   cifIn,
//   cifFr,
//   cifEs,
//   cifPl,
//   // } from '@coreui/icons';
//   // import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons';
//   // import {
//   cilAlignCenter,
//   cilAlignLeft,
//   cilAlignRight,
//   cilApplicationsSettings,
//   cilArrowBottom,
//   cilArrowRight,
//   cilArrowTop,
//   cilArrowThickFromTop,
//   cilAsterisk,
//   cilBan,
//   cilBasket,
//   cilBell,
//   cilBold,
//   cilBookmark,
//   cilCalculator,
//   cilCalendar,
//   cilCloudDownload,
//   cilChartPie,
//   cilCheck,
//   cilChevronBottom,
//   cilChevronLeft,
//   cilChevronRight,
//   cilChevronTop,
//   cilCircle,
//   cilCheckCircle,
//   cilCode,
//   cilCommentSquare,
//   cilCreditCard,
//   cilCursor,
//   cilCursorMove,
//   cilDrop,
//   cilDollar,
//   cilEnvelopeClosed,
//   cilEnvelopeLetter,
//   cilEnvelopeOpen,
//   cilEuro,
//   cilEyedropper,
//   cilFile,
//   cilFullscreen,
//   cilFullscreenExit,
//   cilGlobeAlt,
//   cilGrid,
//   cilGraph,
//   cilHome,
//   cilInbox,
//   cilIndentDecrease,
//   cilIndentIncrease,
//   cilInputPower,
//   cilItalic,
//   cilJustifyCenter,
//   cilJustifyLeft,
//   cilLaptop,
//   cilLayers,
//   cilLightbulb,
//   cilList,
//   cilListNumbered,
//   cilListRich,
//   cilLocationPin,
//   cilLockLocked,
//   cilMagnifyingGlass,
//   cilMap,
//   cilMoon,
//   cilNotes,
//   cilOptions,
//   cilPaperclip,
//   cilPaperPlane,
//   cilPencil,
//   cilPeople,
//   cilPhone,
//   cilPrint,
//   cilPuzzle,
//   cilSave,
//   cilScrubber,
//   cilSettings,
//   cilShare,
//   cilShareAll,
//   cilShareBoxed,
//   cilShieldAlt,
//   cilSpeech,
//   cilSpeedometer,
//   cilSpreadsheet,
//   cilStar,
//   cilSun,
//   cilTags,
//   cilTask,
//   cilTrash,
//   cilUnderline,
//   cilUser,
//   cilUserFemale,
//   cilUserFollow,
//   cilUserUnfollow,
//   cilX,
//   cilClock,
//   cilXCircle,
//   cilBuilding,
//   cilCalendarCheck,
//   cilWarning,
//   cilAccountLogout,
//   cilReload,
//   cilSearch,
//   cilMicrophone,
//   cilVoiceOverRecord
// } from '@coreui/icons';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
    patientSVG,
    clockSVG,
    userSVG,
    pulseSVG,
    taskSVG
  },
  {
    // cilClock,
    // cilBuilding,
    // cilCalendarCheck,
    // cilAlignCenter,
    // cilAlignLeft,
    // cilAlignRight,
    // cilApplicationsSettings,
    // cilArrowBottom,
    // cilArrowRight,
    // cilArrowTop,
    // cilArrowThickFromTop,
    // cilAsterisk,
    // cilBan,
    // cilBasket,
    // cilBell,
    // cilBold,
    // cilBookmark,
    // cilCalculator,
    // cilCalendar,
    // cilCloudDownload,
    // cilChartPie,
    // cilCheck,
    // cilChevronBottom,
    // cilChevronLeft,
    // cilChevronRight,
    // cilChevronTop,
    // cilCircle,
    // cilCheckCircle,
    // cilCode,
    // cilCommentSquare,
    // cilCreditCard,
    // cilCursor,
    // cilCursorMove,
    // cilDrop,
    // cilDollar,
    // cilEnvelopeClosed,
    // cilEnvelopeLetter,
    // cilEnvelopeOpen,
    // cilEuro,
    // cilEyedropper,
    // cilFile,
    // cilFullscreen,
    // cilFullscreenExit,
    // cilGlobeAlt,
    // cilGraph,
    // cilGrid,
    // cilHome,
    // cilInbox,
    // cilIndentDecrease,
    // cilIndentIncrease,
    // cilInputPower,
    // cilItalic,
    // cilJustifyCenter,
    // cilJustifyLeft,
    // cilLaptop,
    // cilLayers,
    // cilLightbulb,
    // cilList,
    // cilListNumbered,
    // cilListRich,
    // cilLocationPin,
    // cilLockLocked,
    // cilMagnifyingGlass,
    // cilMap,
    // cilMoon,
    // cilNotes,
    // cilOptions,
    // cilPaperclip,
    // cilPaperPlane,
    // cilPencil,
    // cilPeople,
    // cilPhone,
    // cilPrint,
    // cilPuzzle,
    // cilSave,
    // cilScrubber,
    // cilSettings,
    // cilShare,
    // cilShareAll,
    // cilShareBoxed,
    // cilShieldAlt,
    // cilSpeech,
    // cilSpeedometer,
    // cilSpreadsheet,
    // cilStar,
    // cilSun,
    // cilTags,
    // cilTask,
    // cilTrash,
    // cilUnderline,
    // cilUser,
    // cilUserFemale,
    // cilUserFollow,
    // cilUserUnfollow,
    // cilX,
    // cilXCircle,
    // cilWarning,
    // cilAccountLogout,
    // cilReload,
    // cilSearch,
    // cilMicrophone,
    // cilVoiceOverRecord
  },
  {
    // cifUs,
    // cifBr,
    // cifIn,
    // cifFr,
    // cifEs,
    // cifPl
  },
  {
    // cibSkype,
    // cibFacebook,
    // cibTwitter,
    // cibLinkedin,
    // cibFlickr,
    // cibTumblr,
    // cibXing,
    // cibGithub,
    // cibStackoverflow,
    // cibYoutube,
    // cibDribbble,
    // cibInstagram,
    // cibPinterest,
    // cibVk,
    // cibYahoo,
    // cibBehance,
    // cibReddit,
    // cibVimeo,
    // cibCcMastercard,
    // cibCcVisa,
    // cibStripe,
    // cibPaypal,
    // cibGooglePay,
    // cibCcAmex
  }
);
