export const userSVG = [
  '110 110',
  `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>User</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="User" transform="translate(0.000000, -0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="g12" transform="translate(16.000000, 16.000000) scale(-1, 1) rotate(-180.000000) translate(-16.000000, -16.000000) translate(0.000000, 0.000000)">
                <path d="M27.9999993,25.3333327 C27.9999993,26.8057327 26.806266,27.9999993 25.3333327,27.9999993 C23.8603994,27.9999993 22.6666661,26.8057327 22.6666661,25.3333327 C22.6666661,23.8609327 23.8603994,22.6666661 25.3333327,22.6666661 C26.806266,22.6666661 27.9999993,23.8609327 27.9999993,25.3333327 Z M31.9999992,21.3333328 C31.9999992,15.4421329 27.2239993,10.6666664 21.3333328,10.6666664 C15.4421329,10.6666664 10.6666664,15.4421329 10.6666664,21.3333328 C10.6666664,27.2245327 15.4421329,31.9999992 21.3333328,31.9999992 C27.2239993,31.9999992 31.9999992,27.2245327 31.9999992,21.3333328 Z M16.6010663,8.88437311 L13.333333,5.3333332 L10.6666664,5.3333332 L10.6666664,2.6666666 L8,2.6666666 L8,0 L0,0 L0,1.72395996 L9.41354643,11.1364531 C9.12759977,11.484373 8.86302645,11.8473997 8.61458645,12.2239597 L0,3.60937324 L0,6.6666665 L9.15363977,15.9322663 C10.5932264,12.7005197 13.279693,10.1510397 16.6010663,8.88437311" id="path14"></path>
            </g>
        </g>
    </g>
</svg>

`
];
