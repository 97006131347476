export const taskSVG = [
  '110 110',
  `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="100px" height="100px" viewBox="0 0 28 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>task</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="task" transform="translate(0.666667, -0.000000)" fill="#FFFFFF">
            <g id="g12" transform="translate(13.333333, 16.000000) scale(-1, 1) rotate(-180.000000) translate(-13.333333, -16.000000) translate(0.000000, 0.000000)">
                <path d="M16,32 L16,18.6666662 L18.6666662,20.6906661 L21.3333328,18.6666662 L21.3333328,32 L26.666666,32 L26.666666,0 L4,0 C1.79062662,0 0,1.79062662 0,4 L0,28 C0,30.2093326 1.79062662,32 4,32 L16,32 Z M23.9999994,5.3333332 L4.65989322,5.3333332 C2.82655993,5.3333332 2.82655993,2.6666666 4.65989322,2.6666666 L23.9999994,2.6666666 L23.9999994,5.3333332" id="path14"></path>
            </g>
        </g>
    </g>
</svg>

`
];
