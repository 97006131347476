export const pulseSVG = [
  '110 110',
  `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="100px" height="100px" viewBox="0 0 32 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>pulse</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="pulse" transform="translate(0.000000, 0.666595)" fill="#FFFFFF">
            <g id="g12" transform="translate(16.000000, 13.333369) scale(-1, 1) rotate(-180.000000) translate(-16.000000, -13.333369) translate(0.000000, 0.000000)">
                <path d="M25.2067994,10.6666664 C22.5015994,7.46510648 18.7239995,3.99322657 14.6666663,0 C6.80937316,7.73333314 0,13.5119997 0,19.1614662 C0,27.366666 10.8187464,29.2770659 14.6666663,22.7291994 C18.4863995,29.2291993 29.3333326,27.3947993 29.3333326,19.1614662 C29.3333326,18.1161329 29.0999993,17.0650662 28.6707993,16 C28.6707993,16 22.2401328,16 20.2999995,16 C19.9625328,16 19.6521328,15.8171996 19.4863995,15.5229329 C19.0921329,14.819733 18.4026662,13.5895997 18.4026662,13.5895997 C18.4026662,13.5895997 16.4598663,17.3863996 15.3759996,19.5051995 C15.226533,19.7973328 14.934933,19.9906662 14.6078663,20.0119995 C14.2802663,20.0333328 13.9650663,19.8827995 13.7786663,19.6135995 C12.7718797,18.1625329 11.2718797,16 11.2718797,16 L9.63593309,16 C9.17447977,16.7973329 8.31354646,17.3333329 7.32655982,17.3333329 C5.85625319,17.3333329 4.65989322,16.1385329 4.65989322,14.6666663 C4.65989322,13.1947997 5.85625319,12 7.32655982,12 C8.31354646,12 9.17447977,12.5359997 9.63593309,13.333333 C9.63593309,13.333333 11.3744797,13.333333 12.2437464,13.333333 C12.5479197,13.333333 12.831773,13.4811997 13.007813,13.7291997 C13.4650663,14.3802663 14.326533,15.6041329 14.326533,15.6041329 C14.326533,15.6041329 16.2374663,11.743733 17.3349329,9.52551976 C17.4863996,9.21978644 17.7921329,9.01978644 18.1317329,9.00677311 C18.4734662,8.99218644 18.7931995,9.16406644 18.9707995,9.4562531 C19.9254662,11.0349331 21.3161328,13.333333 21.3161328,13.333333 L32,13.333333 L32,10.6666664 L25.2067994,10.6666664" id="path14"></path>
            </g>
        </g>
    </g>
</svg>
`
];
