import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { axiosGet, axiosPost } from '../utils/axios'
// import apiRouter from '../utils/apiRouter'

interface dashboardState {
    dashboardDetails: any;
}

// initial state
const initialState: dashboardState = {
    dashboardDetails: []
};

// methods
// export const getTasksCount = createAsyncThunk(
//   'tasks/tasksCount',
//   async (credentials: any) => {
//     const taskDetails = await axiosGet(
//       apiRouter.TASK_BASE_URL, null
//     )
//     if (taskDetails.status) {
//       return taskDetails.data.total
//     }
//   }
// );
// states
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard: (state, { payload }) => {
      state.dashboardDetails = payload
    }
  }
  //   extraReducers: (builder) => {
  //     builder.addCase(getTasksCount.pending, (state) => {
  //       state.taskCount = 'loading...'
  //     })
  //     builder.addCase(getTasksCount.fulfilled, (state, { payload }) => {
  //       state.taskCount = payload
  //     })
  //     builder.addCase(getTasksCount.rejected, (state) => {
  //       state.taskCount = ''
  //     })
  //   }
})

// export const allUsers = (state) =>

export const { setDashboard } = dashboardSlice.actions

export default dashboardSlice.reducer
