import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosGet, axiosPost } from '../utils/axios'
import apiRouter from '../utils/apiRouter'

interface initialStateInt {
  taskCount: any;
}

// initial state
const initialState: initialStateInt = {
  taskCount: ''
};

// methods
export const getTasksCount = createAsyncThunk(
  'tasks/tasksCount',
  async (credentials: any) => {
    const taskDetails = await axiosGet(
      apiRouter.TASK_BASE_URL, null
    )
    if (taskDetails.status) {
      return taskDetails.data.total
    }
  }
);
// states
export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTaskCount: (state, { payload }) => {
      state.taskCount = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTasksCount.pending, (state) => {
      state.taskCount = 'loading...'
    })
    builder.addCase(getTasksCount.fulfilled, (state, { payload }) => {
      state.taskCount = payload
    })
    builder.addCase(getTasksCount.rejected, (state) => {
      state.taskCount = ''
    })
  }
})

// export const allUsers = (state) =>

export const { setTaskCount } = taskSlice.actions

export default taskSlice.reducer
