import React from 'react';

type Props = {
    onChange ?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur ?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder: string;
    name: string;
    value?: string;
    type: string;
    id?: any;
    className?: string;
    required?: boolean;
    valid?:any;
    invalid?:any;
    disabled?: any;
    autoComplete?: any;
    readOnly?: any;
    min?: any;
    title ?: any;
} & typeof defaultProps;

const defaultProps = {
    // required: false,
    type: 'text'
  };

const SimpleInput = ({
    onChange, onBlur, name, placeholder, type, id, className,
    required, valid, invalid, disabled, autoComplete, readOnly, min,
    value = '', title, ...props
    }: Props) => {
    if (invalid !== undefined && valid !== undefined) {
        className = '';
        if (valid) {
            className = 'is-valid';
        }
        if (invalid) {
            className = 'is-invalid';
        }
    }
    return (
        <input
            title={title}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            value={value}
            type={type}
            id={id}
            className={`simple_form-control ${className}`}
            required={required}
            onBlur={onBlur}
            disabled={disabled}
            autoComplete={autoComplete}
            readOnly={readOnly}
            min={min}
            {...props}
        />
    );
}

export default SimpleInput;
