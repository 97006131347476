import axios from 'axios';

export async function loginUser (authData: any) {
  return axios.post(`${process.env.REACT_APP_API_URL}auth`, authData);
}

export async function signout (authData: any) {
  return axios.post(`${process.env.REACT_APP_API_URL}auth/signout`, authData);
}

export async function changePassword (data: any) {
  return axios.post(`${process.env.REACT_APP_API_URL}auth/changepassword`, data);
}

export async function forgotpassword (username: any) {
  return axios.get(`${process.env.REACT_APP_API_URL}auth/forgotpassword?username=${username}`);
}

export async function resetPassword (data: any) {
  return axios.post(`${process.env.REACT_APP_API_URL}auth/resetpassword`, data);
}

export async function refreshTokenApi (data: any) {
  return axios.post(`${process.env.REACT_APP_API_URL}api/auth/refresh_token`, data);
}
