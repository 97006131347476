export const sygnet = [
  '125 125',
  `
    <?xml version="1.0" encoding="UTF-8"?>
<svg width="283px" height="96px" viewBox="0 0 283 96" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Impact-RPM</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Impact-RPM" fill="#ffffff" fill-rule="nonzero">
            <g id="g14-Clipped" transform="translate(141.500000, 48.250112) scale(-1, 1) rotate(-180.000000) translate(-141.500000, -48.250112) translate(0.000000, 0.750112)">
                <g transform="translate(-0.000000, 0.000000)" id="g14">
                    <g transform="translate(-0.000000, 0.000000)">
                        <g id="Group" transform="translate(0.445275, 0.398296)">
                            <g id="g20" transform="translate(0.165917, 0.246477)">
                                <path d="M53.473262,0 L17.3828082,36.0904539 L28.1694146,36.0904539 L30.9500242,36.0904539 L31.7066459,38.7471255 L37.0739621,57.5757107 L50.1479248,11.6841576 L53.473262,0 Z M89.9505225,36.0904539 L53.8605684,0 L57.1864054,11.6841576 L70.2603681,57.5757107 L75.6271845,38.7471255 L76.3843059,36.0904539 L79.1464248,36.0904539 L89.9505225,36.0904539 Z M8.03948006,84.4102927 L8.03948006,84.4102927 C18.7556217,95.1439257 36.2993494,95.1439257 47.0329823,84.4102927 L53.6671651,77.77611 L60.3008481,84.4102927 C71.0169897,95.1439257 88.5607174,95.1439257 99.2943503,84.4102927 C110.010992,73.6936513 110.010992,56.1504234 99.2943503,45.433782 L97.2713619,43.4102938 L81.9090434,43.4102938 L73.7796084,71.9520222 L70.2603681,84.3043457 L66.7411278,71.9520222 L53.6671651,26.0599694 L40.5932024,71.9520222 L37.0739621,84.3043457 L33.536231,71.9520222 L25.4072957,43.4102938 L10.045477,43.4102938 L8.03948006,45.433782 C-2.6766616,56.1504234 -2.6766616,73.6936513 8.03948006,84.4102927" id="path22"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`
];
