import React, { ButtonHTMLAttributes } from 'react';

type ButtonProps = {
    onClick?: () => void;
    type?: 'submit' | 'button' | 'reset';
    size?: any;
    disabled?: boolean;
    text?:any;
    download?:any;
    className?: string;
    children?:any;
    id?:string;
}

const InputBtn = ({ onClick, className, type, id, children, ...rest }: ButtonProps) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`${className}`}
            id={id}
            {...rest}
        >
            {children}
        </button>
    );
}

InputBtn.defaultProps = {
    type: 'button',
    children: 'Submit'
}

export default InputBtn;
