import axios from 'axios'
import { getUserToken, logOut } from './globalFunctions'

// const token = localStorage.getItem('RPMAuthToken') ?? null;

const getBaseUrl = () => {
    switch (process.env.PROJECT_ENV) {
        case 'production':
            return 'http://18.135.238.61:8080/'
        case 'staging':
            return 'http://18.220.255.26:1337/'
        default:
            return 'http://localhost:3000/'
    }
}

export const axiosApi = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer token'
    }
})

const buildUrl = (baseUrl: any, params: any, isStatic: boolean) => {
  if (!params) {
    return baseUrl
  }
  const queryString = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  return `${baseUrl}?${queryString}`
}

export const axiosPost = async (
    url: any,
    data: any,
    token = null,
    contentType = 'application/json'
) => {
    const response: any = {}
    const localToken = getUserToken()
    // const localToken = '123' for generate error

    try {
        const result = await axiosApi.post(url, data, {
            headers: {
                Authorization: `${token || localToken || null}`,
                'Content-Type': contentType
            }
        })
        // response.status = result.status;
        response.status = true
        response.data = result.data
    } catch (e: any) {
        console.log('API E Responce ::', e.response)
        if (e.response) {
            if (e.response.status === 400) {
                response.status = false
                response.message = e.response.data.message
            } else if (e.response?.status === 401) {
                console.log('unauthorized ((')
                response.status = false
                response.message = e?.response?.data?.message ?? 'Unauthorized'
                logOut()
            } else if (e.response.status === 403) {
                response.status = false
                response.message = e.response.data.message
            } else if (e.response.status === 500) {
                response.status = false
                response.message = e?.response?.data?.hint ?? 'There is some issue, please try after sometime.'
            } else {
                response.status = false
                response.message = e?.response?.data?.hint ?? 'something went wrong'
            }
        }
    }
    return response
}

export const axiosPatch = async (
    url: any,
    data: any,
    token = null,
    contentType = 'application/json'
) => {
    const response: any = {}
    const localToken = getUserToken()
    // const localToken = '123' for generate error

    try {
        const result = await axiosApi.patch(url, data, {
            headers: {
                Authorization: `${token || localToken || null}`,
                'Content-Type': contentType
            }
        })
        // response.status = result.status;
        response.status = true
        response.data = result.data
    } catch (e: any) {
        console.log('API E Responce ::', e.response)
        if (e.response) {
            if (e.response.status === 400) {
                response.status = false
                response.message = e.response.data.message
            } else if (e.response?.status === 401) {
                console.log('unauthorized ((')
                response.status = false
                response.message = e?.response?.data?.message ?? 'Unauthorized'
                logOut()
            } else if (e.response.status === 403) {
                response.status = false
                response.message = e.response.data.message
            } else if (e.response.status === 500) {
                response.status = false
                response.message = e?.response?.data?.hint ?? 'There is some issue, please try after sometime.'
            } else {
                response.status = false
                response.message = e?.response?.data?.hint ?? 'something went wrong'
            }
        }
    }
    return response
}

// export const axiosPut = async (
//     url: any,
//     data: any,
//     token = null,
//     contentType = 'application/json'
// ) => {
//     const response: any = {}
//     const localToken = getUserToken()

//     try {
//         const result = await axiosApi.put(url, data, {
//             headers: {
//                 Authorization: `${token || localToken || null}`,
//                 'Content-Type': contentType
//             }
//         })
//         // response.status = result.status;
//         response.status = true
//         response.data = result.data
//     } catch (e: any) {
//         console.log('API E Responce ::', e.response)
//         if (e.response) {
//             if (e.response.status === 400) {
//                 response.status = false
//                 response.message = e.response.data.message
//             } else if (e.response?.status === 401) {
//                 console.log('unauthorized ((')
//                 response.status = false
//                 response.message = 'Unauthorized'
//                 logOut()
//             } else if (e.response.status === 403) {
//                 response.status = false
//                 response.message = e.response.data.message
//             } else if (e.response.status === 500) {
//                 response.status = false
//                 response.message = 'Internal server error'
//             } else {
//                 response.status = false
//                 response.message = 'something went wrong'
//             }
//         }
//     }
//     return response
// }

export const axiosGet = async (
    url: any,
    params = null,
    token = null
) => {
    const response: any = {}
    const localToken = getUserToken()
    const getUrl = await buildUrl(url, params, false)
    try {
        const result = await axiosApi.get(getUrl, {
            headers: {
                Authorization: `${token || localToken || null}`
            }
        })
        response.status = true
        response.data = result.data
    } catch (e: any) {
        console.log('GET API E Responce ::', e.response)
        if (e.response?.status === 400) {
            response.status = false
            response.message = e.response.data.message
        } else if (e.response?.status === 401) {
            console.log(e)
            response.status = false
            response.message = e?.response?.data?.message ?? 'Unauthorized'
            logOut()
        } else if (e.response?.status === 500) {
            response.status = false
            response.message = e?.response?.data?.hint ?? 'Internal server error'
        } else {
            response.status = false
            response.message = e?.response?.data?.hint ?? 'something went wrong'
        }
    }
    return response
}

export const axiosGetPDF = async (
    url: any,
    token = null,
    params = null
) => {
    const response: any = {}
    const localToken = getUserToken()
    const getUrl = await buildUrl(url, params, false)
    try {
        const result = await axiosApi.get(getUrl, {
            responseType: 'arraybuffer',
            headers: {
                Authorization: `${token || localToken || null}`,
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
            }
        })
        response.status = true
        response.data = result.data
    } catch (e: any) {
        console.log('GET API E Responce ::', e.response)
        if (e.response?.status === 400) {
            response.status = false
            response.message = e.response.data.message
        } else if (e.response?.status === 401) {
            console.log(e)
            response.status = false
            response.message = 'Unauthorized'
            logOut()
        } else if (e.response?.status === 500) {
            response.status = false
            response.message = 'Internal server error'
        } else {
            response.status = false
            response.message = 'something went wrong'
        }
    }
    return response
}

export const axiosDelete = async (
    url: any,
    data = null,
    token = null,
    contentType = 'application/json'
) => {
    const localToken = getUserToken()
    const response: any = {}
    try {
        const result = await axiosApi.delete(url, {
            headers: {
                Authorization: `${token || localToken || null}`
            },
            params: data || null
        })
        response.status = true
        response.data = result.data
    } catch (e: any) {
        if (e.response.status === 400) {
            response.status = false
            response.message = e.response.data.message
        } else if (e.response?.status === 401) {
            console.log('unauthorized ((')
            response.status = false
            response.message = 'Unauthorized'
            logOut()
        } else if (e.response.status === 500) {
            response.status = false
            response.message = 'Internal server error'
        } else {
            response.status = false
            response.message = 'something went wrong'
        }
    }
    return response
}
