export const patientSVG = [
  '110 110',
  `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="100px" height="100px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>patient</title>
    <defs>
        <polygon id="path-1" points="0 0 24 0 24 23.94 0 23.94"></polygon>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="patient" transform="translate(0.000000, -0.000000)">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M20.822,19.307 C17.855,18.626 14.244,16.87 15.309,14.584 C15.992,15.71 18.109,16.361 19.759,15.388 C15.012,14.184 22.093,5.917 15.888,1.283 C14.752,0.43 13.361,0 11.975,0 C10.597,0 9.224,0.425 8.112,1.283 C1.907,5.917 8.989,14.184 4.241,15.388 C5.891,16.361 8.011,15.681 8.692,14.584 C9.756,16.87 6.141,18.627 3.178,19.307 C0.2,19.989 0,21.773 0,23.31 C0,23.548 0.005,23.779 0.005,24 L23.995,24 C23.995,23.778 24,23.547 24,23.309 C24,21.772 23.8,19.989 20.822,19.307" id="Fill-1" fill="#FFFFFF" mask="url(#mask-2)"></path>
        </g>
    </g>
</svg>

`
];
