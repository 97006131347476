const getUserToken = () => {
   return localStorage.getItem('RPMAuthToken') ?? null
}

const logOut = () => {
  localStorage.removeItem('RPMAuthToken')
  window.location.reload();
}

export { getUserToken, logOut }
