import { configureStore } from '@reduxjs/toolkit'

import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import taskReducer from './redux/taskSlice'
import toasterReducer from './redux/toasterSlice'
import globalSlice from './redux/globalSlice';
import loginReducer from './redux/loginSlice'
import dashboardSlice from './redux/dashboardSlice';
import pubnubSlice from './redux/pubnubSlice';

const middlewarethunk = [thunk]
const combinedReducer = combineReducers({
  tasks: taskReducer,
  alert: toasterReducer,
  toaster: toasterReducer,
  global: globalSlice,
  dashboard: dashboardSlice,
  user_loggedin: loginReducer,
  pubnubRedux: pubnubSlice
})

export const store = configureStore({
  reducer: combinedReducer,
  middleware: [...middlewarethunk]
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
