export const clockSVG = [
  '110 110',
  `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>clock</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="clock" transform="translate(0.000000, -0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="g12" transform="translate(16.000000, 16.000000) scale(-1, 1) rotate(-180.000000) translate(-16.000000, -16.000000) translate(0.000000, 0.000000)">
                <path d="M23.9999994,13.333333 L14.6666663,13.333333 L14.6666663,23.9999994 L17.3333329,23.9999994 L17.3333329,15.9999996 L23.9999994,15.9999996 L23.9999994,13.333333 Z M16,32 C7.16406649,32 0,24.8363994 0,16 C0,7.16406649 7.16406649,0 16,0 C24.8359994,0 32,7.16406649 32,16 C32,24.8363994 24.8359994,32 16,32" id="path14"></path>
            </g>
        </g>
    </g>
</svg>
`
];
